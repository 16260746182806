
<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Commission Condition Name</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Commission Condition Name</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="CommissionConditionName">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'CommissionConditionNameList'}"><i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group required">
                    <label class="control-label">Name</label>
                    <input type="text" id="name" name="name" :disabled="is_edit" v-bind:class="{'has-error' : errors.has('name')}" v-validate="'required'" v-model="value.name" class="form-control name" placeholder="Name">
                    <div class="help text-danger" v-show="errors.has('name')">
                      {{ errors.first("name") }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label">Description</label>
                    <input type="text" id="description" name="description" :disabled="is_edit" v-bind:class="{'has-error' : errors.has('description')}" v-validate="'required'" v-model="value.description" class="form-control description" placeholder="Description">
                    <div class="help text-danger" v-show="errors.has('description')">
                      {{ errors.first("description") }}
                    </div>
                  </div>
                  <div class="seperator">
                    <h5>Validation Schema</h5>
                    <hr>
                  </div>
                  <div class="form-group required">
                    <label class="control-label" for="type">Datatype</label>
                    <select class="form-control" name="type" id="type" v-bind:class="{ 'has-error': errors.has('type') }" v-validate="'required'" v-model="value.validation_schema.type">
                      <option value="string">String</option>
                      <option value="number">Number</option>
                      <option value="date">Date</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('type')">
                      {{ errors.first("type") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Minimum value/length</label>
                    <input type="number" id="min" name="min" v-model.number="value.validation_schema.min" class="form-control min" placeholder="Minimum">
                    <div class="help text-danger" v-show="errors.has('min')">
                      {{ errors.first("min") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Maximum value/length</label>
                    <input type="number" id="max" name="max" v-model.number="value.validation_schema.max" class="form-control max" placeholder="Maximum value or length">
                    <div class="help text-danger" v-show="errors.has('max')">
                      {{ errors.first("max") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Regular expression</label>
                    <input type="text" id="regex" name="regex" v-model="value.validation_schema.regex" class="form-control regex" placeholder="Regular expression">
                     <div class="help text-danger" v-show="errors.has('regex')">
                      {{ errors.first("regex") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Date format</label>
                    <select class="form-control" name="format" v-bind:class="{ 'has-error': errors.has('format') }" v-validate="" id="format" v-model="value.validation_schema.format">
                      <option value=""></option>
                      <option value="YYYY-MM-DD HH:mm:ss">MySQL Date Format</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('format')">
                      {{ errors.first("format") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Default value</label>
                    <input type="text" id="default" name="default" v-model="value.validation_schema.default" class="form-control default" placeholder="Default value">
                    <div class="help text-danger" v-show="errors.has('default')">
                      {{ errors.first("default") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { CBSCommissionApi } from '@/services/api';

export default {
  name: 'CommissionConditionNameForm',
  components: {
  },
  computed: {
    ...mapGetters(['profile'])
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
    value: {
      validation_schema: {}
    },
    pre: {}
  }),
  methods: {
    handleSubmission () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true;
          if (this.is_edit) {
            this.value.updated_by = this.profile.id
            CBSCommissionApi.updateCommissionConditionName(this.params.id, this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'CommissionConditionNameList' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          } else {
            this.value.organization_ref_id = this.profile.organization_ref_id
            this.value.user_ref_id = this.profile.user_ref_id
            this.value.role_ref_id = this.profile.role_ref_id
            this.value.created_by = this.profile.id
            CBSCommissionApi.storeCommissionConditionName(this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$toaster.success(response.message);
                  this.$router.push({ name: 'CommissionConditionNameList' });
                } else {
                  this.$toaster.error(response.message);
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          }
        }
      });
    },
    getDetail: function (id) {
      CBSCommissionApi.showCommissionConditionName(id)
        .then((item) => {
          this.value = item
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message)
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    async initialize () {
      this.is_loading = true
      this.params = this.$route.params

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        await this.getDetail(this.params.id)
      }
      this.is_loading = false

    }
  },
  mounted () {
    this.initialize()
  }
}
</script>

